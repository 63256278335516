import { format } from 'date-fns';
import { DateStringSchema } from 'src/shared/types';
import { useRestaurantWebQuery } from 'restaurant-v3/src/hooks/useApi';

import type { DomainWithLimitedSymbols } from 'src/shared/types';

export const useDailyLunchMenuQuery = (props: { date: Date; restaurantDomain: DomainWithLimitedSymbols }) => {
    return useRestaurantWebQuery(
        'GET:/menu/daily-lunch-menu',
        {
            query: {
                restaurantDomain: props.restaurantDomain,
                date: DateStringSchema.parse(format(props.date, 'yyyy-MM-dd')),
            },
        },
        {
            refetchInterval: 60000,
        },
    );
};
