'use client';

import React from 'react';
import Box from '@mui/material/Box';
import { IgEmbed } from 'common/modules/instagram/IgEmbed';
import { useRestaurantInfoQuery } from 'restaurant-v3/src/hooks/queries/useRestaurantInfoQuery';
import { useRestaurantDomain } from 'restaurant-v3/src/hooks/queries/useRestaurantDomain';

import { ReservationLink } from './ReservationLink';

// Note - match this value in 'loading.tsx' placeholder
const FIXED_HEIGHT = '528px';

export const ReservationsAndIgSection: React.FC = () => {
    const restaurantDomain = useRestaurantDomain();
    const { data: restaurantInfoResult } = useRestaurantInfoQuery({ restaurantDomain });
    if (
        !restaurantInfoResult.result.success
    ) {
        return null;
    }
    return (
        <Box
            sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                    flexDirection: 'column',
                    gap: 2,
                    mb: 2,
                },
                [theme.breakpoints.up('md')]: {
                    gap: 1,
                    height: FIXED_HEIGHT,
                },
                display: 'flex',
                flexWrap: 'nowrap',
            })}
        >
            <Box
                sx={(theme) => ({
                    [theme.breakpoints.down('md')]: {
                        height: FIXED_HEIGHT,
                    },
                    [theme.breakpoints.up('md')]: {
                        flexGrow: 1,
                        flexBasis: '66%',
                        height: '100%',
                    },
                })}
            >
                <ReservationLink />
            </Box>
            {restaurantInfoResult.result.payload.socials.instagram !== null && (
                <Box
                    sx={(theme) => ({
                        [theme.breakpoints.only('md')]: {
                            py: 2,
                        },
                        [theme.breakpoints.up('md')]: {
                            borderRadius: 5,
                            borderColor: 'neutral200.main',
                            borderWidth: 1,
                            borderStyle: 'solid',
                        },
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    })}
                >
                    <IgEmbed
                        profileId={restaurantInfoResult.result.payload.socials.instagram.profileId}
                    />
                </Box>
            )}
        </Box>
    );
};

