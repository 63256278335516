import { useRestaurantWebQuery } from 'restaurant-v3/src/hooks/useApi';

import type { DomainWithLimitedSymbols } from 'src/shared/types';

export const useRestaurantInfoQuery = (props: { restaurantDomain: DomainWithLimitedSymbols }) => {
    return useRestaurantWebQuery(
        'GET:/restaurant-info',
        {
            query: {
                restaurantDomain: props.restaurantDomain,
            },
        },
        {
            refetchInterval: 60000,
        },
    );
};
